import { forwardRef, useContext, useEffect, useState } from "react"
import { ShowChatContext, ToastMessageContext } from "../../../App"
import Info from "./info/Info"
import Cover from "./cover/Cover";


const MainProfile = forwardRef((props, ref) => {

    const {showChat} = useContext(ShowChatContext);

    const [infoOpen, setInfoOpen] = useState(false);

    const [coverOpen, setCoverOpen] = useState(false);

    const [more, setMore] = useState(false);

    const {successToastCall} = useContext(ToastMessageContext);

    useEffect(()=>{
        if(localStorage.getItem("cover")){
            document.getElementById("cover_image").src = localStorage.getItem("cover");
        }else{
           document.getElementById("cover_image").src = "img/cover1.svg";
           localStorage.setItem("cover", "img/cover1.svg")
        }
    }, []);

    const changeCover = (value) =>{
        document.getElementById("cover_image").src = value;
        localStorage.setItem("cover", value)
    }


    document.body.addEventListener("click", ()=>{
        if(more){
            setMore(false)
        }
    })
    
    const showMore = (event) =>{
        event.preventDefault();
        event.stopPropagation();
        setMore(!more)
    }

    const copyPortfolio = () =>{
        navigator.clipboard.writeText("https://dambel.dev/");
        successToastCall("Portfolio URL copied", "success");
    }

    const showInfo = () =>{
        setInfoOpen(!infoOpen);
    }

    const showCover = () =>{
        setCoverOpen(!coverOpen);
    }

    return (
        <div className="main_section main_profile_section">
            <div className="main_cover_wrapper">
                <img src="" alt="Profile cover background" className="cover_image" id="cover_image"/>
                <div className="edit_cover_button" onClick={showCover}>
                    <span className="fa-solid fa-pen"></span>
                </div>
            </div>
            <div className="main_profile_wrapper">
                <div className="mp_image_wrapper">
                    <img src="img/profile.webp" alt="Profile"/>
                </div>
                <div className="mp_info_wrapper">
                    <div className="mp_info_left">
                        <h1 className="mp_name">Damjan Belojević</h1>
                        <p className="mp_company">Software developer</p>

                        <div className="location_contact_wrapper">
                            <p>Belgrade, Serbia</p><span className="dot">.</span><button onClick={showInfo} className="mp_contact_info">Contact Info</button>
                        </div>

                        <p className="connections">500+ connections</p>
                        <div className="mp_profile_buttons">
                            <button className="mp_message_button" onClick={showChat}><span className="fa-solid fa-paper-plane"></span> Message</button>
                            <div className="mp_more_wrapper">
                                <button className="mp_more_button" onClick={showMore}>More</button>
                                {
                                    more &&
                                    <div className="more_wrapper">
                                        <button className="more_btn" onClick={copyPortfolio}><span className="fa-regular fa-copy"></span>&nbsp;&nbsp;Copy Portfolio URL</button>
                                        <a href="files/Damjan_Belojević_CV.pdf" download target="_blank">
                                            <button className="more_btn">
                                                <span className="fa-solid fa-download"></span>&nbsp;&nbsp;Download my CV
                                            </button>
                                        </a>

                                        
                                    </div>
                                }
                                
                            </div>
                            
                            
                            
                        </div>
                    </div>
                    <div className="mp_info_right">
                        <div className="comanies_wrapper">
                            <div className="company_block">
                                <div className="company_image">
                                    <img src="img/fc.webp" alt="Company" />
                                </div>
                                <div className="company_name">
                                    FlowCandy
                                </div>
                            </div>
                            <div className="company_block">
                                <div className="company_image">
                                    <img src="img/singi.webp" alt="Company" />
                                </div>
                                <div className="company_name">
                                    Singidunum University
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
               
            </div>

            { infoOpen && <Info openInfo={showInfo}/>}

            { coverOpen && <Cover openCover={showCover}  changeCover={changeCover} ref={ref}/>}

        </div>
    )
});

export default MainProfile